export const et = {
    translations: {
        "rodytiVisus": "Näita kõik",
        "imonesLabel:":"Ettevõtte nimi",
        "1.1: Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?": "Kas oleksite nõus oma naabruses elama järgmiste inimestega?",
        "1.2: Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?": "Kas oleksite nõus töötama samas ettevõttes järgmiste inimestega?",
        "2.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus": "Kas teie meeskond on teie arvates mitmekesine",
        "2.2: Ką darbovietė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?": "2.2: Mida teeb töökoht mitmekesisuse ja töötajate heaolu tagamiseks?",
        "2.3: Ko norėtumėte, kad darbovietė imtųsi siekiant užtikrinti įvairovę ir darbuotojų gerovę?": "2.3: Mida sooviksite, et töökoht teeks mitmekesisuse ja töötajate heaolu tagamiseks?",
        "3.1: Klausimai darbo pokalbio metu": "3.1: Küsimused töövestluse ajal",
        "3.2: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje?": "3.2: Kas olete kogenud diskrimineerimist või ebaõiglast kohtlemist praegusel töökohal?",
        "4.2: Hipotetinių situacijų vertinimas": "4.2: Hüpoteetiliste olukordade hindamine",
        "4.3: Ar darbuotojai patyrė diskriminacijos arba ar su Jais buvo elgiamasi nesąžiningai dabartinėje darbo vietoje": "4.3: Kas töötajad on kogenud diskrimineerimist või ebaõiglast kohtlemist praegusel töökohal",
        "4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami?": "4.4: Kas teate kolleegi, keda on töökohal diskrimineeritud?",
        "5.1: Kiek žinote apie iššūkius, su kuriais susiduria skirtingi gyventojai?": "5.1: Kui palju teate erinevatele elanikele esitatavatest väljakutsetest?",
        "5.2: Darbuotojų savijauta darbe": "5.2: Töötajate enesetunne tööl",
        "5.3: Darbovietės vertinimas": "5.3: Töökoha hindamine",
        "6.1: Darbuotojų įtraukimas į sprendimų priėmimą": "6.1: Töötajate kaasamine otsuste tegemisse",

        "Nera_ats": "Vastuseid pole",
        "Nera_atsakymu": "Vastuseid pole",
        "Prašome atsakyti į klausimą": "Palun vastake küsimusele",

        kalba_text: ['LT', 'EN', 'RU', 'ET', 'LV'],
        kalba: ['lt', 'en', 'ru', 'et', 'lv'],

        // Add other translations similarly
    }
}
