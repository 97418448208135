import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { lt } from './lt';
import { ru } from './ru';
import { et } from './et';
import { lv } from './lv';

 i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: 'lt',
      supportedLngs: ['lt', 'en', 'ru', 'et', 'lv'],
      react: {
        useSuspense: false //   <---- this will do the magic
      },
      compatibilityJSON: 'v3',
      // we init with resources

      resources: {
        lt: lt,
        en: en,
        ru: ru,
        et: et,
        lv: lv
      },
      debug: false,
      fallbackLng: 'lt',

      // have a common namespace used around the full app
      ns: ["translations"],
      defaultNS: "translations",

      keySeparator: '', // we use content as keys
      returnObjects: true,
      interpolation: {
        escapeValue: false
      }

    });

    export default i18n;
