
export const lt = {
    translations: {
        "surveyTitle": "Apklausa",
        "atsakymas": "Atsakymas",
        "1.1: Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?":"Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?", 
        "1.2: Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?": "Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?",
        "2.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus pagal:": "Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus",
        "2.2: Ką darbovietė daro, kad užtikrintų įvairovę ir darbuotojų gerovę? (be balų)": "Ką darbovietė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?",
        "2.3: Ko norėtumėte, kad darbovietė imtųsi siekiant užtikrinti įvairovę ir darbuotojų gerovę? (be balų)": "Ko norėtumėte, kad darbovietė imtųsi siekiant užtikrinti įvairovę ir darbuotojų gerovę?",
        "3.1: Ar darbo pokalbio metu įmonė Jūsų klausė apie:": "Klausimai darbo pokalbio metu",
        "3.2: Jeigu dabartinis darbdavys ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kurie iš šių kriterijų galėtų daryti įtakos sprendimui dėl vieno arba kito kandidato pasirinkimo?": "Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje?",
        "4.2: Kaip Jūs vertinate šias hipotetines situacijas:": "Hipotetinių situacijų vertinimas",
        "4.3: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:": "Ar darbuotojai patyrė diskriminacijos arba ar su Jais buvo elgiamasi nesąžiningai dabartinėje darbo vietoje",
        "4.3: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų: (be balų)": "Ar darbuotojai patyrė diskriminacijos arba ar su Jais buvo elgiamasi nesąžiningai dabartinėje darbo vietoje",
        "4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami dėl savo:": "Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami?",
        "4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami dėl savo: (be balų)": "Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami?",
        "5.1: Kiek žinote apie iššūkius, su kuriais susiduria gyventojai, kurie yra:": "Kiek žinote apie iššūkius, su kuriais susiduria skirtingi gyventojai?",
        "5.2: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "Darbuotojų savijauta darbe",
        "5.3: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "Darbovietės vertinimas",
        "6.1: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "Darbuotojų įtraukimas į sprendimų priėmimą",
        "2. Požiūris apie įvairovę ir įtrauktį darbo vietoje": "Požiūris apie įvairovę ir įtrauktį darbo vietoje",
        "1. Socialinės distancijos vertinimas":"Socialinės distancijos vertinimas",
        "3. Įdarbinimo vertinimas": "Įdarbinimo vertinimas",
        "3.2: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje?": "Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje?",
        "2.3: Ar darbo atrankos metu Jūsų dabartinis  darbdavys sakė, kad stengiasi atsižvelgti į kandidačių/ -tų bei darbuotojų individualius poreikius (dėl kalbos, negalios, kita), teiravosi, ar Jūs tokių turite?":"Ar darbo atrankos metu Jūsų dabartinis  darbdavys sakė, kad stengiasi atsižvelgti į kandidačių/ -tų bei darbuotojų individualius poreikius (dėl kalbos, negalios, kita), teiravosi, ar Jūs tokių turite?",
        "4.1: Ar darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?":"Ar darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?",
        "4.5: Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar jaustumėtės saugus  apie tai papasakoti kolegoms?":"Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar jaustumėtės saugus  apie tai papasakoti kolegoms?",
        "4.6: Ar Jūsų darbo vietoje yra saugus pranešimo kanalas?":"Ar Jūsų darbo vietoje yra saugus pranešimo kanalas?",
        "4.7:  Jei Jūs ar Jūsų kolega patirtų diskriminaciją darbo vietoje, ar norėtumėte apie tai pranešti?":"Jei Jūs ar Jūsų kolega patirtų diskriminaciją darbo vietoje, ar norėtumėte apie tai pranešti?",
        "5.4:  Ar dabartinėje darbo vietoje teko patirti patyčių ar priekabiavimo?":"Ar dabartinėje darbo vietoje teko patirti patyčių ar priekabiavimo?",
        "2.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus": "Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus",
        "7. Socialinės ir demografinės charakteristikos":"Socialinės ir demografinės charakteristikos",
        "8.1: Ar esate tikintis/i?": "Ar esate tikintis/i?",
        "8.4: Ar esate Lietuvos pilietis/ė?": "Ar esate Lietuvos pilietis/ė?",
        "1.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus pagal:": "Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus pagal:",
        "4.3: Ar patyrėte diskriminaciją arba ar su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:" : "Ar patyrėte diskriminaciją arba ar su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:",
"1.2: Ką įmonė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?": "Ką įmonė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?",

"1.3: Ko įmonė nedaro, bet Jūs norėtumėte, kad darytų užtikrinant darbuotojų įvairovę ir gerovę?": "Ko įmonė nedaro, bet Jūs norėtumėte, kad darytų užtikrinant darbuotojų įvairovę ir gerovę?",

"1.4: Manau, kad mano darbovietė turėtų skirti daugiau dėmesio šioms sritims:": "Manau, kad mano darbovietė turėtų skirti daugiau dėmesio šioms sritims:",

"2.1: Ar darbo pokalbio metu Jūsų dabartinis darbdavys klausė Jūsų apie:": "Ar darbo pokalbio metu Jūsų dabartinis darbdavys klausė Jūsų apie:",

"2.2: Jeigu dabartinis darbdavys ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kurie iš šių kriterijų galėtų daryti įtakos sprendimui dėl vieno arba kito kandidato pasirinkimo?": "Jeigu dabartinis darbdavys ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kurie iš šių kriterijų galėtų daryti įtakos sprendimui dėl vieno arba kito kandidato pasirinkimo?",

"3.1: Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?": "Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?",

"3.2: Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?": "Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?",

"4.1: Ar dabartinėje darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?": "Ar dabartinėje darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?",

"4.3: Ar patyrėte diskriminacijos arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:": "Ar patyrėte diskriminacijos arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:",

"4.7: Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar norėtumėte apie tai pranešti?": "Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar norėtumėte apie tai pranešti?",

"7.7: Kokia Jūsų pilietybė?": "Kokia Jūsų pilietybė?",

"8.3: Ar turite fizinę negalią?": "Ar turite fizinę negalią?",

"8.4: Ar esate neuroskirtinga/s (pavyzdžiui, turite autizmą, aktyvumo ir dėmesio sutrikimą, kt.)?": "Ar esate neuroskirtinga/s (pavyzdžiui, turite autizmą, aktyvumo ir dėmesio sutrikimą, kt.)?",
"8.2: Ar priklausote LGBT+ bendruomenei?": "Ar priklausote LGBT+ bendruomenei?",
"8.3: Ar turite negalią?": "Ar turite negalią?",
"8. Kiti klausimai (neprivaloma)":"Kiti klausimai (neprivaloma)",
"8.4: Ar esate Lietuvos pilietis/ė?": "Ar esate Lietuvos pilietis/ė?",
"2.2: Ką darbovietė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?": "Ką darbovietė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?",
"2.3: Ko norėtumėte, kad darbovietė imtųsi siekiant užtikrinti įvairovę ir darbuotojų gerovę?": "Ko norėtumėte, kad darbovietė imtųsi siekiant užtikrinti įvairovę ir darbuotojų gerovę?",
"3.1: Ar darbo pokalbio metu Jūsų klausė apie:": "Ar darbo pokalbio metu Jūsų klausė apie:",
"3.2: Jeigu Jūsų darbovietė ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kas galėtų daryti neigiamos įtakos atsirenkant vieną iš šių kandidatų?": "Jeigu Jūsų darbovietė ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kas galėtų daryti neigiamos įtakos atsirenkant vieną iš šių kandidatų?",
// "4.1: Ar darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?": "Ar darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?",
"4.2: Hipotetinių situacijų vertinimas": "Hipotetinių situacijų vertinimas",
"4.3: Ar patyrėte diskriminaciją arba ar su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:": "Ar patyrėte diskriminaciją arba ar su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:",
"4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami?": "Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami?",
"4.5: Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar jaustumėtės saugiai apie tai papasakoti kolegoms?": "Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar jaustumėtės saugiai apie tai papasakoti kolegoms?",
// "4.6: Ar Jūsų darbo vietoje yra saugus pranešimo kanalas?": "Ar Jūsų darbo vietoje yra saugus pranešimo kanalas?",
"4.7: Jei Jūs ar Jūsų kolega patirtų diskriminaciją darbo vietoje, ar norėtumėte apie tai pranešti?": "Jei Jūs ar Jūsų kolega patirtų diskriminaciją darbo vietoje, ar norėtumėte apie tai pranešti?",
"5.1: Kiek žinote apie iššūkius, su kuriais susiduria skirtingi gyventojai?": "Kiek žinote apie iššūkius, su kuriais susiduria skirtingi gyventojai?",
"5.2: Prašome įvertinti kiekvieną klausimą apie Jūsų darbovietę naudodami skalę.": "Prašome įvertinti kiekvieną klausimą apie Jūsų darbovietę naudodami skalę.",
"5.3: Prašome įvertinti kiekvieną klausimą apie Jūsų darbovietę naudodami skalę.": "Prašome įvertinti kiekvieną klausimą apie Jūsų darbovietę naudodami skalę.",
"5.4: Ar dabartinėje darbo vietoje teko patirti patyčių ar priekabiavimo?": "Ar dabartinėje darbo vietoje teko patirti patyčių ar priekabiavimo?",
"6.1: Prašome įvertinti kiekvieną klausimą apie Jūsų darbovietę naudodami skalę.": "Prašome įvertinti kiekvieną klausimą apie Jūsų darbovietę naudodami skalę.",
"7.1: Kokia yra Jūsų lytis?": "Kokia yra Jūsų lytis?",
"4.3: Ar patyrėte diskriminaciją arba ar su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:": "Ar patyrėte diskriminacijos arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:",
"7.2: Koks Jūsų amžius?": "Koks Jūsų amžius?",
"7.3: Kokia Jūsų šeiminė padėtis?": "Kokia Jūsų šeiminė padėtis?",
"7.4: Kiek laiko dirbate šioje organizacijoje?": "Kiek laiko dirbate šioje organizacijoje?",
"7.5: Kokios Jūsų pareigos?": "Kokios Jūsų pareigos?",
"​​​7.8: Kokia Jūsų pilietybė?":"​​​Kokia Jūsų pilietybė?",
"8.4: Ar esate neuroskirtinga/s (pavyzdžiui, turite autizmą, aktyvumo ir dėmesio sutrikimą, kt.)?":"Ar esate neuroskirtinga/s (pavyzdžiui, turite autizmą, aktyvumo ir dėmesio sutrikimą, kt.)?",
"7.6: Ar darbo vietoje naudojatės asmeniniu kompiuteriu?": "Ar darbo vietoje naudojatės asmeniniu kompiuteriu?",
"7.7: Koks Jūsų išsilavinimas?": "Koks Jūsų išsilavinimas?",
"8.1: Ar esate tikintis/i?": "Ar esate tikintis/i?",
"​​​7.7: Kokia Jūsų pilietybė?": "Kokia Jūsų pilietybė?",
"8.4: Ar esate neuroskirtinga/s (pavyzdžiui, turite autizmą, aktyvumo ir dėmesio sutrikimą, kt.)?": "Ar esate neuroskirtinga/s (pavyzdžiui, turite autizmą, aktyvumo ir dėmesio sutrikimą, kt.)?",
"4.3: Ar patyrėte diskriminacijos arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų: ": "Ar patyrėte diskriminacijos arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų: ",
"8.2: Kokia Jūsų seksualinė orientacija?": "Kokia Jūsų seksualinė orientacija?",
"8.3: Ar turite negalią?": "Ar turite negalią?",
        "4. Įvairovės ir įtraukties vertinimas darbo vietoje": "Įvairovės ir įtraukties vertinimas darbo vietoje",
        "2. Požiūris apie įvairovę ir įtrauktį darbo vietoje": "Požiūris apie įvairovę ir įtrauktį darbo vietoje",
        "5. Darbuotojų gerovė": "Darbuotojų gerovė",
        "6. Įtraukimas į sprendimų priėmimą": "Įtraukimas į sprendimų priėmimą",
        "Organizacijos atstovės/-o apklausa": "Organizacijos atstovės/-o apklausa",
        "Kviečiame atsakyti į keletą klausimų apie Jūsų atstovaujamos organizacijos sprendimus, susijusius su įvairovės valdymu ir įtrauktimi. Tikimės, kad šis vertinimas bei darbuotojų apklausa padės kurti dar geresnę, saugesnę ir įtraukesnę darbo aplinką Jūsų kolektyvui. \n\nApklausai užpildyti preliminariai reikės iki 15 minučių.\n\nŠią apklausą kūrė ir ja su Jūsų organizacija dalinasi Lietuvos Įvairovės Chartija. Dėl klausimų ar komentarų kviečiame rašyti diversity@diversity.lt": "Kviečiame atsakyti į keletą klausimų apie Jūsų atstovaujamos organizacijos sprendimus, susijusius su įvairovės valdymu ir įtrauktimi. Tikimės, kad šis vertinimas bei darbuotojų apklausa padės kurti dar geresnę, saugesnę ir įtraukesnę darbo aplinką Jūsų kolektyvui. \n\nApklausai užpildyti preliminariai reikės iki 15 minučių.\n\nŠią apklausą kūrė ir ja su Jūsų organizacija dalinasi Lietuvos Įvairovės Chartija. Dėl klausimų ar komentarų kviečiame rašyti diversity@diversity.lt",
        "Nera_ats": "Nėra atsakymų",
        "Nera_atsakymu": "Nėra atsakymų",
        "Prašome atsakyti į klausimą": "Prašome atsakyti į klausimą",
        "amziusLabel": "Amžius",
        "ExportTable": "lentelė",
        "apklausos_label": "Apklausos",
        "arIstrintApklausa": "Ar tikrai norite ištrinti apklausą?",
        "arIstrintProfili": "Ar tikrai norite ištrinti įmonės profilį?",
        "atlikti_apklausa": "Atlikti vertinimą",
        "balas": "Balas",
        "balas_palygintas": "Balas palygintas su Lietuvos vidurkiu",
        "bendrasApklausosIvertinimas": "Bendras apklausos įvertinimas (iš 10 galimų)",
        "bendrasDarbuotojuApklausosIvertinimas": "Darbuotojų apklausos vertinimas",
        "bendrasIvertinimas": "Bendras įvertinimas",
        "bendrasOrganizacijosAtstovu": "Organizacijos atstovų apklausos vertinimas",
        "create": "+ Nauja apklausa",
        "create_imone": "+ Pridėti įmonę",
        "darbuotojų_apklausa": "Įvairovės ir įtraukties vertinimas",
        "esu_organizacijos_atstovas": "Esu įgaliotas organizacijos atstovas ir galiu organizicijos vardu kurti paskyrą",
        "filtruoti": "Filtruoti",
        "formosPavadinimas": "Apklausos pavadinimas",
        "imones_label": "Įmonės",
        "imones_pav": "Įmonės pavadinimas",
        "issaugoti": "Išsaugoti",
        "issilavinimaiLabel": "Išsilavinimas",
        "iveskiteImone": "Įveskite įmonės pavadinimą",
        'iveskiteImoneMin': 'Neteisingas įmonės pavadinimas',
        "iveskitePass": "Slaptažodį turi sudaryti mažiausiai 6 simboliai",
        "ivestiName": "Įveskite vardą ir pavardę",
        "kompiuteriaiLabel": "Ar darbo vietoje naudojasi asmeniniu kompiuteriu?",
        "kopijuoti": "Kopijuoti",
        "kopijuoti_nuoroda": "Kopijuoti nuorodą",
        "laikaiLabel": "Kiek laiko dirba",
        "laukia_patvirtinimo": "Laukia patvirtinimo",
        "login_email": "El. paštas",
        "login_pass": "Slaptažodis",
        "login_pass_pakartot": "Pakartoti slaptažodį",
        "lytysLabel": "Lytis",
        "ne": "Ne",
        "bar_chart": "Stulpelinė diagrama",
        "radial_chart" : "Radialinė diagrama",
        "chart_type": "Diagramos tipas",
        "perziureti_rezultatus": "Peržiūrėti rezultatus",
        "nebegalioja": "Nebegalioja",
        "negaliosLabel": "Ar turi negalią?",
        "nepatvirtinta": "Nepatvirtinta",
        "neteisingasEmail": "Neteisingas el. paštas",
        "neteisingasTel": "Neteisingas tel. nr. (+...)",
        "neteisingiDuomenys": "Neteisingi prisijungimo duomenys",
        "new_login_pass": "Naujas slaptažodis",
        "orientacijosLabel": "Seksualinė orientacija",
        "padetysLabel": "Šeimyninė padėtis",
        "pamirsai_pass": "Pamiršai slaptažodį?",
        "pareigomsLabel": "Pareigos įmonėje",
        "patvirtinta": "Patvirtinta",
        "patvirtinti_reg": "Patvirtinti registraciją",
        "paviesinta": "Paviešinta",
        "perziureti_rezultatus": "Peržiūrėti rezultatus",
        "pildytojuTipaiLabel": "Pasirinkti",
        "prenumeratos_label": "Prenumeratos galiojimo data",
        "prisijungti": "Prisijungti",
        "prisiminti": "Priminti",
        "profilio_redagavimas": "Profilio redagavimas",
        "profilis": "Profilis",
        "redaguoti": "Redaguoti",
        "registruoti": "Registruoti",
        "registruotis": "Registruotis",
        "religijosLabel": "Ar tikintis/i",
        "rezultataiRezultatai": "Rezultatai",
        "rezultataiTendencijos": "Balai",
        "rodoma": "Rodoma:",
        "sekminga_reg": "Registracija sėkminga!",
        "sekminga_reg_txt1": "Jūsų profilį aktyvuosime arba su Jumis susisieksime per 3 darbo dienas. Kilus klausimams kviečiame mums parašyti el. paštu diversity@diversity.lt",
        "slaptazodzio_priminimas": "Slaptažodžio priminimas",
        "slaptazodzio_priminimas_txt": "Norėdami prisiminti slaptažodį, įveskite el. pašto adresą, kurį naudojate prisijungimui",
        "statusas_label": "Statusas",
        "sukurti_apklausa": "Skurti apklausą",
        "sukurti_imone": "Įmonės registracija",
        "susipazinau_su_taisyklem": "Susipažinau su privatumo politika ir taisyklėmis",
        "taip": "Taip",
        "telNr": "Tel. Nr.",
        "tipas_label": "Formos rūšis",
        "trinti": "Ištrinti",
        "vardas_pavarde": "Vardas Pavardė",
        "visosImonesLabel": "Pasirinkti įmonę",
        "diskriminacija": "Darbuotojai, kuriems sunkiau atpažinti galimą diskriminaciją",
        "priminimasIvyko": "Nuoroda slaptažodžio atkūrimui išsiųsta",
        "max3filtrai": "Pasirinkite ne daugiau 3 filtrų",
        "slaptazodisPakeistas": "Slaptažodis pakeistas",
        "atliko": "Apklausą atliko: {{kiek}}",
        "accComfirmedError": "Vartotojas nepatvirtintas",
        "accValidToError": "Prenumeratos galiojimas baigėsi",
        rezultatai: {
            title: "Atliko įvairovės ir įtraukties vertinimą savo organizacijoje. 2023 m. balas:",
            balai: "{{balas}} iš 10",
            share: "Balo vizualas",
        },
        grafikai: {
            komentaras: "Komentaras",
            rodyti: "Rodyti",
            paslepti: "Paslėpti",
            lentelėsVaizdas: "Lentelės vaizdas"
        },
        amziaiText: ['18-34 metai', '35-54 metai', '55-74 metai', '75 metai ar vyresni', 'Nesakysiu'],
        issilavinimaiText: ['Pradinis', 'Vidurinis', 'Aukštasis', 'Kita'],
        kompiuteriaiText: ['Darbo reikmėms turiu asmeninį kompiuterį', 'Dirbu prie bendro naudojimo kompiuterių', 'Nedirbu su kompiuteriu'],
        laikaiText: ['Iki 1 m.', '1-5 m.', '5-10 m.', 'Virš 10 m.'],
        linkaiAdminText: ['Balai', 'Rezultatai', 'Apklausos', 'Įmonės', 'Statistika', ''],
        linkaiNeAdminText: ['Balai', 'Rezultatai', 'Atlikti vertinimą'],
        lytysText: ['Vyras', 'Moteris', 'Kita', 'Nesakysiu'],
        negaliosText: ['Taip', 'Ne', 'Nesakysiu'],
        orientacijosText: ['Aseksualus/-i', 'Biseksualus/-i', 'Homoseksualus/-i', 'Heteroseksualus/-i', 'Kita', 'Nesakysiu'],
        pildytojuTipaiText: ['Darbuotojų apklausa', "Organizacijos atstovės/-o apklausa"],
        pildytojuTipaiText2: ['Darbuotojų apklausa', "Organizacijos atstovės/-o apklausa"],
        'Įmonės forma': "Organizacijos atstovės/-o apklausa",
        'Darbuotojo forma': 'Darbuotojų apklausa',
        'All': 'Bendras rezultatas',
        tipas_text: ['Darbuotojų apklausa', 'Organizacijos atstovės/-o apklausa'],
        padetysText: ['Vedęs/Ištekėjusi', 'Partnerystėje', 'Vienas (-a)', 'Išsiskyręs (-usi)', 'Nesakysiu', 'Kita'],
        pareigomsText: ['Aukščiausio lygio vadovas/ė', 'Vidutinio lygio vadovas/ė', 'Pirmojo lygio vadovas/ė', 'Darbuotojas/ specialistė', 'Kita'],
        religijosText: ['Taip', 'Ne', 'Nežinau', 'Nesakysiu'],
        statusas_text: ['Sustabdyta', 'Nepaviešinta', 'Paviešinta'],
        menuAdmin: ['Imonės', 'Formų redaktorius', 'Rezultatai'],
        prenumeratos_options: ['Vieneri metai', 'Neribotai', 'Sustabdyta'],
        metai: "Metai",

        amziai: ['18-34 metai', '35-54 metai', '55-74 metai', '75 metai ar vyresnis', 'Nesakysiu'],
        issilavinimai: ['Pradinis', 'Vidurinis', 'Aukštasis', 'Kita'],
        kalba_text: ['LT', 'EN', 'RU', 'ET', 'LV'],
        kalba: ['lt', 'en', 'ru', 'et', 'lv'],
        kompiuteriai: ['Darbo reikmėms turiu asmeninį kompiuterį', 'Dirbu prie bendro naudojimo kompiuterių', 'Nedirbu su kompiuteriu'],
        laikai: ['Iki 1 m.', '1-5 m.', '5-10 m.', 'Virš 10 m.'],
        linkaiAdmin: ['Tendencijos', 'Rezultatai', 'Apklausos', 'Imones', 'Statistika'],//nekeisti
        linkaiNeAdmin: ['Tendencijos', 'Rezultatai', 'ImonesApklausos'],
        lytys: ['Vyras', 'Moteris', 'Kita', 'Nesakysiu'],//nekeisti
        negalios: ['Taip', 'Ne', 'Nesakysiu'],
        Nera_ats: '-',

        orientacija: ['Aseksualus', 'Biseksualus', 'Homoseksualus', 'Heteroseksualus', 'Kita', 'Nesakysiu'],//nekeisti
        orientacijos: ['Aseksualus', 'Biseksualus', 'Homoseksualus', 'Heteroseksualus', 'Kita', 'Nesakysiu'],//nekeisti
        padetys: ['Vedęs/Ištekėjusi', 'Partnerystėje', 'Vienas (-a)', 'Išsiskyręs (-usi)', 'Nesakysiu', 'Kita'], //Nekeisti
        pareigoms: ['Aukščiausio lygio vadovas/ė', 'Vidutinio lygio vadovas/ė', 'Pirmojo lygio vadovas/ė', 'Darbuotojas/ specialistė', 'Kita'], // nekeisti
        pildytojuTipai: ['Darbuotojo forma', 'Įmonės forma'],//nekeisti
        pildytojuTipai2: ['Darbuotojo forma', 'Įmonės forma'],//nekeisti
        prenumeratos: [new Date(), new Date('3000'), new Date('1971')], //nekeisti
        pslNerodo: ['7. Socialinės ir demografinės charakteristikos', 
            '8. Kiti klausimai (neprivaloma)', '0. welcome', 
            '2.4 Organizacijos charakteristikos ir duomenų kaupimas', 
            '2.3 Įtrauki darbo vietos aplinka',
            '2.4 Išorinė ir vidinė komunikacijos',
            '2.1.8: Ar įmonė turi lygių galimybių įgyvendinimui skirtą veiksmų planą?',
            '2.3: Ko įmonė nedaro, bet Jūs norėtumėte, kad darytų užtikrinant darbuotojų įvairovę ir gerovę?',
            '2.4: Manau, kad mano darbovietė turėtų skirti daugiau dėmesio šioms sritims:',
            '1.4: Manau, kad mano darbovietė turėtų skirti daugiau dėmesio šioms sritims'],
        pslNerodoCSV: ['0. welcome'],
        questionNerodyti: ['2.2: Ką įmonė daro, kad užtikrintų įvairovę ir darbuotojų gerovę? (be balų)',
            '4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami dėl savo: (be balų)',
            '4.3: Ar patyrėte diskriminaciją arba ar su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų: (be balų)',
//             '4.1.1: Apie ką buvo juokaujama arba kas buvo komentuojama?',
//             'Kitos priežastys (įrašykite)',
            '2.3: Ko norėtumėte, kad įmonė imtųsi siekdama užtikrinti įvairovę ir darbuotojų gerovę? (be balų)',
            // '2.3: Ko įmonė nedaro, bet Jūs norėtumėte, kad darytų užtikrinant darbuotojų įvairovę ir gerovę?',
//             '2.4: Manau, kad mano darbovietė turėtų skirti daugiau dėmesio šioms sritims:',
//             '4.3: 4.2: Ar patyrėte diskriminacijos arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų: (be balų)', 
            "1.4: Manau, kad mano darbovietė turėtų skirti daugiau dėmesio šioms sritims:",
            
//             "2.1.8: Ar įmonė turi lygių galimybių įgyvendinimui skirtą veiksmų planą?",
//             "2.1.2: Ar Jūsų organizacija turi lygių galimybių įgyvendinimui skirtą veiksmų planą?",
//             "2.1.9: Ar įmonė turi smurto ir priekabiavimo prevencijos politiką?",
"2.1.3: Ar Jūsų organizacija priklauso bent vienai nacionalinei ar tarptautinei iniciatyvai, kuri skatina Į&Į arba verslo etiką (pabrėžiant lygias galimybes)?"
//             "2.1.3: Ar Jūsų organizacija priklauso bent vienai nacionalinei ar tarptautinei iniciatyvai, kuri skatina Į&Į arba verslo etiką (pabrėžiant lygias galimybes)?",
// "2.3: Ar darbo atrankos metu Jūsų dabartinis  darbdavys sakė, kad stengiasi atsižvelgti į kandidačių/ -tų bei darbuotojų individualius poreikius (dėl kalbos, negalios, kita), teiravosi, ar Jūs tokių turite?",
        ],
        religija: ['Taip', 'Ne', 'Nežinau', 'Nesakysiu'],//nekeisti
        religijos: ['Taip', 'Ne', 'Nežinau', 'Nesakysiu'],//nekeisti
        statusas: ['Sustabdyta', 'Nepaviešinta', 'Paviešinta'], //nekeisti
        tipas: ['Darbuotojo forma', 'Įmonės forma'], //nekeisti
        atlikti_apklausa: 'Atlikti vertinimą',
        apklausa_atlikta: 'Vertinimas atliktas',
        kopijuoti_nuoroda: 'Kopijuoti nuorodą',
        sukurti_apklausa: 'Sukurti apklausą',
        nepatvirtinta: 'Nepatvirtinta',
        Nera_atsakymu: "Nėra atsakymų",
        bendrasDarbuotojuApklausosIvertinimas: 'Darbuotojų apklausos vertinimas',
        bendrasOrganizacijosAtstovu: 'Organizacijos atstovų apklausos vertinimas ',
        bendrasIvertinimas: 'Bendras įvertinimas',
        Nera_ats: '-',
        pp: 'Privatumo politika',
        pt: 'Paslaugų teikimo taisyklės',
        paviesintaNekeisti: 'Paviešinta',
        email_patvirtinimas_1: 'Laba diena,',
        email_patvirtinimas_2: 'Šiuo laišku patvirtiname, kad Jūsų registracija Lietuvos Įvairovės chartijos sistemoje yra sėkminga. Kviečiame atlikti vertinimą ir sužinoti, kaip Jums sekasi užtikrinti įvairovės valdymą ir darbuotojų gerovę savo organizacijoje.',
        email_patvirtinimas_3: 'Prisijungti prie sistemos ir pradėti vertinimą galite paspaudę ant šios nuorodos: <a href="https://vertinimas.diversity.lt">https://vertinimas.diversity.lt</a>',
        email_patvirtinimas_4: 'Jei turėtumėte klausimų, kviečiame susisiekti el. paštu ',
        email_patvirtinimas_email: 'diversity@diversity.lt',
        email_patvirtinimas_5: 'Pagarbiai,',
        email_patvirtinimas_6: 'Įvairovės chartijos komanda',
        iveskite_nauja_pass: 'Įveskite naują slaptažodį',
        ivairove_patvirtintas_acc: 'Vartotojas patvirtintas',
        //2025.02.10
        rodytiVisus: 'Rodyti visus',
        pasirinkti:'Apklausos tipas',
        imonesLabel:'Pasirinkti įmonę',
        Year:'Apklausos metai',
        perziureti_rezultatus: 'Peržiūrėti rezultatus',
        Create:'Sukurti apklausą',
        Create_imone:'Sukurti įmonę',
        

        //2023.08.03
        //=========//
        //30.1
        // lytis
        'Vyras': 'Vyras',
        'Moteris': 'Moteris',
        'Kita': 'Kita',
        'Nesakysiu': 'Nesakysiu',
        "Lytis": 'Lytis',
        //Amzius
        "Amzius": "Amzius",
        '18-34 metai': '18-34 metai',
        '35-54 metai': '35-54 metai',
        '55-74 metai': '55-74 metai',
        '75 metai ar vyresnis': '75 metai ar vyresnis',
        //Padetis padetysLabel padetysText:
        "Padetis": 'Šeimyninė padėtis',
        'Vedęs/Ištekėjusi': 'Vedęs/Ištekėjusi',
        'Partnerystėje': 'Partnerystėje',
        'Vienas (-a)': 'Vienas (-a)',
        'Išsiskyręs (-usi)': 'Išsiskyręs (-usi)',
        //Laiko laikaiLabel laikaiText: 
        'Kiek laiko': 'Kiek laiko',
        'Iki 1 m.': 'Iki 1 m.',
        '1-5 m.': '1-5 m.',
        '5-10 m.': '5-10 m.',
        'Virš 10 m.': 'Virš 10 m.',
        //Pareigos   pareigomsLabel  pareigomsText:
        'Pareigos': 'Pareigos įmonėje',
        'Aukščiausio lygio vadovas/ė': 'Aukščiausio lygio vadovas/ė',
        'Vidutinio lygio vadovas/ė': 'Vidutinio lygio vadovas/ė',
        'Pirmojo lygio vadovas/ė': 'Pirmojo lygio vadovas/ė',
        'Darbuotojas/ specialistė': 'Darbuotojas/ specialistė',


        //Kompiuretis "kompiuteriaiLabel": "Ar darbo vietoje naudojasi asmeniniu kompiuteriu?" kompiuteriaiText,
        "Kompiuteris": "Kompiuteris",
        'Darbo reikmėms turiu asmeninį kompiuterį': 'Darbo reikmėms turiu asmeninį kompiuterį',
        'Dirbu prie bendro naudojimo kompiuterių': 'Dirbu prie bendro naudojimo kompiuterių',
        'Nedirbu su kompiuteriu': 'Nedirbu su kompiuteriu',
        //Issilavinimas  issilavinimaiLabel issilavinimaiText: 
        "Issilavinimas": "Išsilavinimas",
        'Pradinis': 'Pradinis',
        'Vidurinis': 'Vidurinis',
        'Aukštasis': 'Aukštasis',
        //Religija religijosLabel   religijosText: 
        'Religija': 'Ar tikintis/i',
        'Taip': 'Taip',
        'Ne': 'Ne',
        'Nežinau': 'Nežinau',

        //Orientacija "orientacijosLabel", orientacijosText: 
        'Orientacija': 'Seksualinė orientacija/-i',
        'Aseksualus': 'Aseksualus/-i',
        'Biseksualus': 'Biseksualus/-i',
        'Homoseksualus': 'Homoseksualus/-i',
        'Heteroseksualus': 'Heteroseksualus/-i',
        //Negalia negaliosLabel negaliosText: ,
        'Negalia': 'Ar turi negalią?',

        "palyginus_su_vidurkiu": 'Balas lygynamas su kitų įmonių balų vidurkiu.',
        'atlikti_vertinima_label': "Atlikti vertinimą",
        'atsisiusti_visus': 'JPG',
        'atidaryti_visus': 'Rodyti visus',
        'paslepti_visus': 'Paslėpti visus',
        'is_10_galimu': 'Iš 10 galimų',
        'nevertinama': '-',

       //2.5.1: Koks yra Jūsų veiklos sektorius?
        'Gamyba': 'Gamyba',
        'Žemės ūkis': 'Žemės ūkis',
        'Miškininkystė': 'Miškininkystė',
       'Žvejyba': 'Žvejyba',
        'Kasyba': 'Kasyba',
        'Statyba': 'Statyba',
        'Prekyba': 'Prekyba',
        'Transportas': 'Transportas ir logistika',
'Finansai': 'Finansai ir draudimas',
'IT': 'Informacinės technologijos',
'Švietimas': 'Švietimas',
'Sveikatos': 'Sveikatos priežiūra',
'Paslaugos': 'Paslaugų sektorius',
'Turizmas': 'Turizmas ir apgyvendinimas',
'Energetika': 'Energetika',


        //Koks yra Jūsų įmonės tipas?
        'private': 'Privati įmonė',
'public': 'Viešojo sektoriaus įstaiga',
'ngo': 'Nevyriausybinė organizacija',
'education': 'Švietimo įstaiga',
'research': 'Mokslo įstaiga',
'stateowned': 'Valstybinė įmonė',
'municipal': 'Savivaldybės įstaiga',
'other': 'Kita',


        //Taisyklės
        'pp1': 'Paskutinį kartą atnaujinta: 2023 m. sausio mėn 31 d.',
        'pp2': 'Tai yra įrankio, vertinančio įvairovę ir įtrauktį organizacijose (toliau - Įrankis) privatumo politika, kuri taikoma registruotų vartotojų (toliau - Jūsų) asmens duomenų tvarkymui. Įrankio komandos branduolį sudaro Lietuvos Įvairovės chartijos asociacijos (registracijos kodas 302877111, adresas Lukiškių g. 5-527, Vilnius) darbuotojai. Lietuvos Įvairovės chartijos asociacija yra Įrankio tikslais tvarkomų duomenų valdytoja (toliau – Mes).',
        'pp3': 'Įrankio tikslas - įvertinti įvairovės ir įtraukties būklę Jūsų kolektyve. Kad galėtume pasiekti šį tikslą privalome tvarkyti asmens duomenis, kuriuos Jūs pateikiate užsiregistruodami ir naudodamiesi Įrankiu.',
        'pp4': 'Tvarkydami Jūsų asmens duomenis mes laikomės Bendrojo asmens duomenų apsaugos reglamento (toliau – BDAR), Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo, taip pat kituose teisės aktuose nustatytų asmens duomenų tvarkymo reikalavimų.',
        'pp5': 'Kokias paslaugas galite gauti Įrankyje?',
        'pp6': 'Leidžiame susikurti Įrankyje paskyrą (profilį), kurioje nurodote savo vardą, pavardę, el. pašto adresą, atstovaujamą organizaciją.',
        'pp7': 'Leidžiame naudotis Įrankyje esančiais klausimynais, kuriais Jūs galite pasidalinti su savo darbuotojais ir pakviesti juos konfidencialiai atsakyti į Įrankyje pateiktus klausimus. Darbuotojų atsakymai išsaugomi ir Jūs galite su nuasmenintais atsakymais susipažinti Įrankyje.',
        'pp8': 'Įrankyje leidžiame naudotis dvejais klausimynais - vienas yra skirtas organizacijos atstovei/-ei, kitas yra skirtas darbuotojams. Abiejų klausimynų atsakymai agreguojami į bendrą balą ir pateikiami taip, kad Jūs galėtumėte įvertinti, kaip Jūsų kolektyve jaučiasi dirbantys asmenys, kokios jų patirtys, ir kiek Jūsų atstovaujama organizacija yra pasiruošusi užtikrinti atvirą ir įtraukią darbo aplinką.',
        'pp9': 'Kokius duomenis mes renkame, saugome ir naudojame?',
        'pp10': 'Siekdami suteikti aukščiau nurodytas paslaugas, Jūs pateikiate, o mes saugome šią informaciją:',
        'pp11': 'Duomenų kategorija',
        'pp12': 'Duomenų tipas',
        'pp13': 'Privalomi registracijos duomenys (būtini registracijai Įrankyje įvykdyti)',
        'pp14': 'Vardas;',
        'pp15': 'Pavardė;',
        'pp16': 'El. pašto adresas;',
        'pp17': 'Atstovaujamos organizacijos pavadinimas',
        'pp18': 'Prisijungimo duomenys',
        'pp19': 'Unikalus Jūsų prieigos raktas, kuris sukuriamas, kai pirmą kartą užsiregistruojate Įrankyje. Šis prieigos raktas reikalingas tam, kad galėtumėte prisijungti prie paskyros, dalintis su darbuotojais apklausomis, pamatyti nuasmenintus apklausų rezultatus.',
        'pp20': 'Ryšių duomenys (Jums pasidalinus nuoroda į Įrankio klausimyną su darbuotojais)',
        'pp21': 'Atsakymai į klausimyno klausimus.',
        'pp22': 'Slaptažodžių politika',
        'pp23': 'Susikurdami paskyrą Įrankyje, Jūs pasirenkate prisijungimo slaptažodį. Nacionalinis kibernetinio saugumo centras rekomenduoja naudoti sudėtingus slaptažodžius, kurie yra ne trumpesni nei 12 simbolių ir sudaryti iš didžiųjų raidžių, mažųjų raidžių, skaitmenų bei specialiųjų simbolių. Rekomenduojama naudoti unikalų slaptažodį, kuris niekur kitur nenaudojamas.',
        'pp24': 'Neatskleiskite savo paskyros slaptažodžio tretiesiems asmenims. Jeigu atskleisite savo slaptažodį ar bet kokią kitą paskyros informaciją kitiems asmenims, Jūs būsite atsakingas už visus veiksmus atliktus naudojantis Jūsų paskyra.',
        'pp25': 'Jūsų slaptažodis yra prisijungimo prie Jūsų paskyros raktas. Mes Jūsų slaptažodžių nekaupiame. Prisijungę prie savo paskyros Jūs galite bet kuriuo metu keisti ir atnaujinti savo duomenis. Pamiršus slaptažodį galėsite susikurti naują.',
        'pp26': 'Kokiais tikslais ir kokiu teisiniu pagrindu tvarkome Jūsų asmens duomenis ir kiek laiko juos saugome?',
        'pp27': 'Duomenų tvarkymo tikslas ir aplinkybės',
        'pp28': 'Tvarkomų duomenų kategorijos',
        'pp29': 'Duomenų tvarkymo teisinis pagrindas',
        'pp30': 'Duomenų saugojimo laikotarpis',
        'pp31': 'Sukurti Jūsų profilį ir suteikti pagrindines Įrankio paslaugas',
        'pp32': 'Privalomi registracijos duomenys',
        'pp33': 'Prisijungimo raktas',
        'pp34': 'Teisėtas interesas (BDAR 6 str. 1 d. f) p.) suteikti ir gauti paslaugas',
        'pp35': 'Duomenys tvarkomi tol, kol Jūsų paskyra yra aktyvi, t.y., kol Jūs jos neištrynėte',
        'pp36': 'Duomenų analizė siekiant tobulinti Įrankio veiklą ir gerinti naudotojų patirtį',
        'pp37': 'Agreguoti ir anonimizuoti techniniai duomenys',
        'pp38': 'Agreguoti ir anonimizuoti privalomi registracijos duomenys',
        'pp39': 'Teisėtas interesas (BDAR 6 str. 1 d. f) p.) tobulinti Įrankyje siūlomas paslaugas ir Jūsų interesas gauti kokybiškas paslaugas',
        'pp40': 'Anonimizuoti (Jūsų tapatybės neidentifikuojantys) asmens duomenys gali būti saugomi neribotą laiką',
        'pp41': 'Nustatyti ir šalinti Įrankio technines spragas ir užtikrinti saugumo reikalavimus',
        'pp42': 'Techniniai duomenys',
        'pp43': 'Kitos duomenų kategorijos, priklausomai nuo incidentų pobūdžio',
        'pp44': 'Teisėtas interesas (BDAR 6 str. 1 d. f) p.) užtikrinti saugumą ir užkirsti kelią nesąžiningiems trečiųjų šalių veiksmams',
        'pp45': 'Duomenys tvarkomi tol, kol yra išsprendžiama techninė problema arba saugumo incidentas ir 1 metus po incidento pabaigos',
        'pp46': 'Atsakyti į Įrankio vartotojų užklausas ir prašymus',
        'pp47': 'Susirašinėjimo arba ryšių duomenys',
        'pp48': 'Teisėtas interesas (BDAR 6 str. 1 d. f) p.) užtikrinti Paslaugų tęstinumą ir kokybišką teikimą',
        'pp49': 'Teisinė prievolė (BDAR 6 str 1 d. c) p.) įgyvendinti duomenų subjektų teises pagal BDAR',
        'pp50': 'Duomenys saugomi 6 mėn. po paskutinio atsakymo į Jūsų užklausą',
        'pp51': 'Pasibaigus nurodytam duomenų saugojimo laikotarpiui, mes ištrinsime Jūsų duomenis, išskyrus atvejus, kai tai būtina teisinėms pareigoms (pvz. atsakyti į teisines pretenzijas) ir norminiams reikalavimams (pvz., pateikti duomenis kompetentingos institucijoms esant teisiniam pagrindui) vykdyti.',
        'pp52': 'Su kuo galime pasidalinti Jūsų asmens duomenimis?',
        'pp53': 'Mes neperduosime Jūsų asmens duomenų tretiesiems asmenimis, išskyrus kai tai yra būtina mūsų teikiamoms paslaugoms užtikrinti ar to reikalauja teisės aktai. Dėl šių priežasčių, Jūsų asmens duomenys gali būti perduoti šioms gavėjų grupėms:',
        'pp54': 'Įrankio techninio palaikymo ir techninės infrastruktūros (pvz., duomenų prieglobos ir saugojimo) paslaugas teikiančioms bendrovėms;',
        'pp55': 'Prisijungimo įskiepio paslaugas teikiančioms bendrovėms “Facebook” ir “Google”. Šios bendrovės gali tvarkyti Jūsų duomenis trečiosiose valstybėse, dėl to rekomenduojame susipažinti su šių bendrovių pateikta informacija ir standartinių sutarčių sąlygomis:',
        'pp56': 'Facebook',
        'pp57': 'Google',
        'pp58': 'Kompetentingoms valstybės institucijoms, bet tik tuo atveju, jeigu to reikalauja teisės aktai ar teismų sprendimai;',
        'pp59': 'Agreguota ir anonimizuota (nesuteikiančia galimybės identifikuoti Jūsų kaip asmens) informacija galime pasidalinti su platesne auditorija, informuodami ją apie Įrankyje teikiamas paslaugas.',
        'pp60': 'Jūsų teisės',
        'pp61': 'Tvarkant asmens duomenis, mes užtikriname Jūsų teises pagal BDAR bei Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymą. Jūs turite šias teises:',
        'pp62': 'taisyti ar papildyti, patikslinti ',
        'pp63': 'neteisingus ar netikslius Jūsų asmens duomenis. Tai padaryti galite Įrankio skiltyje „Profilis“;',
        'pp64': 'ištrinti savo paskyrą ',
        'pp65': 'ir taip sunaikinti savo asmens duomenis. Jums ištrynus savo paskyrą, Jūsų asmens duomenys ir Jūsų atstovaujamos organizacijos duomenys bus ištrinti iš sistemos, mes jų nebeturėsime ir negalėsime atkurti. Tokiu atveju Jūs nebegalėsite naudotis Įrankiu, o mes - padėti Jūsų organizacijai įvertinti savo būklę įvairovės ir įtraukties srityje;',
        'pp66': 'susipažinti su Jūsų duomenimis',
        'pp67': ', kuriuos esame surinkę, kitaip tariant, pateiktume apie Jus turimų duomenų kopiją. Savo registracijos duomenis galite matyti Įrankio skiltyje “Profilis”;',
        'pp68': 'prašyti apriboti duomenų tvarkymą ',
        'pp69': '– jei manote, kad Jūsų duomenis tvarkome neteisėtai ar mūsų turimi duomenys apie Jus yra neteisingi, galite prašyti, kad laikinai nenaudotume Jūsų duomenų. Tokiu atveju, iki kol bus išspręsti teisėtumo ar teisingumo klausimai, duomenis galėtume tik saugoti, o kitiems veiksmams su jais atlikti reikėtų atskiro Jūsų sutikimo;',
        'pp70': 'prašyti nesutikti su duomenų tvarkymu ',
        'pp71': '– jei manote, kad yra konkrečių su Jumis susijusių priežasčių, dėl kurių neturėtume tvarkyti Jūsų duomenų, taip pat galite kreiptis į mus nurodant šias priežastis ir prašyti netvarkyti Jūsų duomenų;',
        'pp72': 'pateikti skundą priežiūros institucijai ',
        'pp73': '– jei manote, kad Jūsų duomenis naudojame neteisėtai, taip pat galite pateikti skundą duomenų apsaugą prižiūrinčiai institucijai. Lietuvoje tai - Valstybinė duomenų apsaugos inspekcija.',
        'pp74': 'Prašymą dėl Jūsų teisių įgyvendinimo galite pateikti ir su mumis susisiekti el.paštu diversity@diversity.lt ',
        'pp75': 'Privatumo politikos atnaujinimas',
        'pp76': 'Mes galime šią privatumo politiką laikas nuo laiko keisti, kad ji atspindėtų esamą situaciją. Atlikę pakeitimus, šio puslapio viršuje nurodysime atnaujinimo datą. Rekomenduojame Jums periodiškai peržvelgti mūsų saugumo politiką tam, kad žinotumėte, kaip mes saugome ir tvarkome Jūsų asmens duomenis.',
        'pp77': 'Patvirtinu, kad esu deleguota(s) ir turiu teisę kurti paskyrą savo atstovaujamos organizacijos vardu. Mano atstovaujama organizacija priisima visą atsakomybę už pateiktos informacijos tikslumą.',
        add: (key, value) => { lt.translations[key] = value },

        // Capture-related translations
    element_not_found: 'Elementas nerastas',
    error_generating_image: 'Klaida generuojant paveikslėlį',
    preparing_download: 'Ruošiamas atsisiuntimas...',
    loading: 'Kraunama...'
}
}

const Statistika = {
    'statistika': 'Statistika',
    'atstovas': 'Astovė/-as',
    'atsake': 'Atsakė',
    'neatsake': 'Neatsakė',
    'darbuotojai': 'Darbuotojai'
}

const AddObject = (obj) => {
    Object.keys(obj).map((keys) => {
        lt.translations.add(keys, obj[keys])
    })
}

AddObject(Statistika)
