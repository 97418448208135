import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './index.css';

export default function SingleSelect({
    set, 
    size, 
    label, 
    classNameBox, 
    classNameForm, 
    classNameSelectLabel, 
    filterTag, 
    options = [], 
    optionsText = [], 
    value,
    current,
    onChange,
    sx, 
    sx0, 
    sx1, 
    ...rest
}) {
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        } else if (set) {
            set(event.target.value, filterTag, event);
        }
    };

    return (
        <Box
            className={classNameBox}
            sx={sx0 ?? { minWidth: 120 }}
        >
            <FormControl
                className={classNameForm}
                sx={sx ?? { m: 1, width: 300 }}
            >
                <InputLabel 
                    className={classNameSelectLabel} 
                    size={size} 
                    id="demo-simple-select-label"
                >
                    {label}
                </InputLabel>
                <Select
                    sx={sx1}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={current || value || ''}
                    label={label}
                    onChange={handleChange}
                    size={size}
                    {...rest}
                >
                    {options.map((option, index) => (
                        <MenuItem key={option} value={option}>
                            {optionsText[index] || option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
