export const lv = {
    translations: {
        "rodytiVisus": "Rādīt visu",
        "1.1: Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?": "Vai jūs piekrītat dzīvot kaimiņos ar tālāk minētajiem cilvēkiem?",
        "1.2: Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?": "Vai jūs piekrītat strādāt tajā pašā uzņēmumā ar tālāk minētajiem cilvēkiem?",
        "2.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus": "Vai, jūsuprāt, jūsu kolektīvs ir daudzveidīgs",
        "2.2: Ką darbovietė daro, kad užtikrintų įvairovę ir darbuotojų gerovę?": "2.2: Ko darba vieta dara, lai nodrošinātu daudzveidību un darbinieku labklājību?",
        "2.3: Ko norėtumėte, kad darbovietė imtųsi siekiant užtikrinti įvairovę ir darbuotojų gerovę?": "2.3: Ko jūs vēlētos, lai darba vieta darītu, lai nodrošinātu daudzveidību un darbinieku labklājību?",
        "3.1: Klausimai darbo pokalbio metu": "3.1: Jautājumi darba intervijas laikā",
        "3.2: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje?": "3.2: Vai esat piedzīvojis diskrimināciju vai netaisnīgu attieksmi pašreizējā darba vietā?",
        "4.2: Hipotetinių situacijų vertinimas": "4.2: Hipotētisko situāciju novērtējums",
        "4.3: Ar darbuotojai patyrė diskriminacijos arba ar su Jais buvo elgiamasi nesąžiningai dabartinėje darbo vietoje": "4.3: Vai darbinieki ir piedzīvojuši diskrimināciju vai netaisnīgu attieksmi pašreizējā darba vietā",
        "4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami?": "4.4: Vai jūs zināt kolēģi, kurš ir piedzīvojis diskrimināciju darba vietā?",
        "5.1: Kiek žinote apie iššūkius, su kuriais susiduria skirtingi gyventojai?": "5.1: Cik daudz jūs zināt par izaicinājumiem, ar kuriem saskaras dažādi iedzīvotāji?",
        "5.2: Darbuotojų savijauta darbe": "5.2: Darbinieku pašsajūta darbā",
        "5.3: Darbovietės vertinimas": "5.3: Darba vietas novērtējums",
        "6.1: Darbuotojų įtraukimas į sprendimų priėmimą": "6.1: Darbinieku iesaiste lēmumu pieņemšanā",
        
        "Nera_ats": "Nav atbilžu",
        "Nera_atsakymu": "Nav atbilžu",
        "Prašome atsakyti į klausimą": "Lūdzu, atbildiet uz jautājumu",

        kalba_text: ['LT', 'EN', 'RU', 'ET', 'LV'],
        kalba: ['lt', 'en', 'ru', 'et', 'lv'],

        // Add other translations similarly
    }
}
